/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@use '@angular/material' as mat;

/* Core CSS required for Ionic components to work properly */
@use '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@use '@ionic/angular/css/normalize.css';
@use '@ionic/angular/css/structure.css';
@use '@ionic/angular/css/typography.css';
@use '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@use '@ionic/angular/css/padding.css';
@use '@ionic/angular/css/float-elements.css';
@use '@ionic/angular/css/text-alignment.css';
@use '@ionic/angular/css/text-transformation.css';
@use '@ionic/angular/css/flex-utils.css';
@use '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@use 'animate.css/animate.min.css';

// custom CSS files

@use '../src/theme/utilities.scss';
@use '../src/theme/full-callendar.scss';
@use '../src/theme/button-animation.scss';

:root {
  $secondary-color: var(ion-color-primary);
}

* {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  scrollbar-width: thin;
  scrollbar-color: var(--ion-color-tertiary) #ffffff00;
}

body {
  --ion-font-family: 'Roboto';
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
  }
}

// wyłaczone na potrzeby notcha aby nie dodawało podwójnych margiensów
ion-header ion-toolbar:first-of-type {
  padding-top: 0 !important;
}

.ion-item {
  height: 1px;
  z-index: -1;
  --min-height: 1px;
}

.ion-page {
  overflow: visible !important;
}

app-first-run {
  background-color: lightgrey;
}

ion-content {
  --background: var(--ion-color-light);
}

ion-router-outlet {
  overflow: visible !important;
  ion-content {
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 25px;
    --padding-end: 25px;
  }
}

ion-content[full] {
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
}

ion-button .button-middle {
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: initial;
}
ion-button {
  --border-radius: 6px;
  white-space: inherit !important;
}

ion-button[type='outline'],
ion-button.outline {
  --background: transparent;
  --color: var(--button-outline--color);

  --border-width: 2px;
  --border-color: var(--button-outline--color);
  --border-radius: 6px;
  --border-style: solid;
}

ion-button[type='pink'],
ion-button.pink {
  --background: var(--button-pink--color);
  --color: var(--button-outline--color);
}

ion-back-button.md {
  margin-inline-end: 0 !important;
}

.menu-header-button {
  height: 42px;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  ion-icon {
    --ionicon-stroke-width: 56px;
    font-size: 19px !important;
  }
}

.sc-ion-input-ios,
.sc-ion-input-md,
.native-textarea {
  padding-left: 8px !important;

  .sc-ion-input-ios,
  .sc-ion-input-md {
    padding-left: 0px !important;
  }
}

div.pink-btn {
  background: var(--button-pink--color);
  color: var(--button-outline--color);

  border-radius: 6px;
  padding: 8px 12px 8px 8px;
  display: flex;
  align-items: center;

  cursor: pointer;
}

ion-button[type='transparent'] {
  --background: transparent;
  --color: var(--button-outline--color);
}

ion-button[type='add'],
ion-button[type='white-rounded-left'],
ion-button[type='white-rounded-right'] {
  --background: rgba(255, 255, 255, 0.1);
  --border-radius: 20px;
  min-width: 36px;
  --padding-end: 0px;
  --padding-start: 0px;
}

ion-button[type='white-rounded-left'],
ion-button[type='white-rounded-right'] {
  --background: var(--ion-color-white);

  ion-icon {
    position: relative;
  }
}

ion-button[type='white-rounded-left'] {
  ion-icon {
    right: 1px;
  }
}

ion-button[type='white-rounded-right'] {
  ion-icon {
    left: 1px;
  }
}

ion-button[type='close'] {
  --background: transparent;
  --color: var(--ion-color-secondary);
  --padding-end: 0px;
  --padding-start: 0px;
  --box-shadow: none;
  width: 38px;
}

ion-button {
  text-transform: none;
}

a.primary {
  text-decoration: none;
  color: var(--button-outline--color);
  font: Medium 14px;
}

.grid-header {
  background-color: var(--ion-color-light);
}

select {
  border: 1px solid #ededed;
  border-radius: 5px;
  background: #fbfbfb;
  width: 100%;
  height: 34px;
}

.control-display,
.control-display-no-margin {
  width: 100%;
}

.control-display {
  margin: auto;
  margin-top: 16px;
  ion-label {
    font-size: 16px !important;
    color: black !important;
    max-width: 300px !important ;
  }
  @media (max-width: 992px) {
    margin-top: 8px;
    ion-label {
      font-size: 13px !important;
      color: black !important;
      max-width: 300px !important ;
    }
  }
  &--small-dropdown {
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &--invalid {
    --color-focused: var(--ion-color-danger) !important;
    --highlight-color-invalid: var(--ion-color-danger) !important;
    --highlight-background: var(--ion-color-danger) !important;
    caret-color: var(--ion-color-danger);
  }
  &--valid {
    --color-focused: var(--ion-color-success) !important;
    --highlight-color-valid: var(--ion-color-success) !important;
    --highlight-background: var(--ion-color-success) !important;
    caret-color: var(--ion-color-success);
  }
  &--no-margin-top {
    margin-top: 0;
  }
}

ion-item.input {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --inner-padding-start: 0px;
  --border-color: var(--input--border--color);
  --border-width: 0px;
  border-radius: 6px;
  padding-bottom: 0px;
  overflow: visible;
}

ion-item.only-label {
  height: 32px;
}

ion-textarea {
  --background-color: var(--ion-color-white);
  border: 2px solid var(--input--border--color);
  border-radius: 6px;
  min-height: 49px;
}

ion-input,
ion-item.selectable,
ion-searchbar {
  --background-color: var(--ion-color-white);
  border: 2px solid var(--input--border--color);
  border-radius: 6px;
  height: 42px;
  margin-top: 0px;
}

.sc-ion-searchbar-ios-h {
  border: none !important;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

ion-item.selectable {
  display: flex;
  align-items: center;
  margin-top: 16px;
  overflow: hidden !important;
}

ion-input.small-font {
  font-size: 0.8rem;

  background-color: var(--ion-color-white);
}

div.searchbar-input-container {
  --box-shadow: none;
  z-index: -1;
}

.input {
  ion-input ion-datetime ion-select ion-textarea {
    position: relative;
    top: 1px;
  }
}

ion-input.search {
  --padding-end: 0.5em;
  --padding-start: 14px !important;
  max-width: 100%;
  height: 42px;
  background: var(--ion-color-white);
  margin-top: 0px;
}

ion-input.search::after {
  content: ' ';
  background-image: url('./assets/icons/magnificer.svg');
  background-repeat: no-repeat;
  background-size: auto 50%;
  background-position: left 13px center;

  width: 36px;
  height: 36px;
}
.clear {
  position: absolute;
  right: 0;
}

ion-select {
  --background-color: var(--ion-color-white);
  --padding-end: 10px;
  border: 2px solid var(--input--border--color);
  border-radius: 6px;
  height: 50px;
  margin-top: 6px;
  min-width: 100%;
}

.alert-radio-inner.sc-ion-alert-md {
  background-color: var(--button-outline--color);
}

.button-margin-top-md {
  margin-top: 26px;
  margin-left: 10px;
  &:nth-of-type(1) {
    margin-left: 0px;
  }
}

.button-margin-top-sm {
  margin-top: 16px;
}

.cart-modal {
  --height: 50%;
  --border-radius: 10px;
  padding: 25px;
}

.image-preview-present .modal-wrapper {
  width: 85%;
  height: 85%;
  background-color: transparent;
  box-shadow: none;
}

.modal-input-present .modal-wrapper {
  max-width: 400px;
  max-height: 400px;
  padding: 0;
  margin: 0;
  height: 100%;
  border-radius: 6px;
}

.modal-input-present {
  .scroll-y {
    touch-action: pan-y;
    overflow-y: var(--overflow);
    overscroll-behavior-y: contain;
    background-color: white;
  }
  ion-col {
    max-height: 100%;
    background-color: white;
    margin-top: 0;
    padding-top: 0;
  }
  ion-row {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    max-height: 230px;
  }
}

.add-guest-modal .modal-wrapper,
.add-modal .modal-wrapper {
  height: auto;
  min-height: 630px;
  max-width: 475px;
  margin-right: 0;
  margin-left: 0;

  @media (max-width: 760px) {
    border: 0;
  }
  border-radius: 6px;
}

.add-category-modal .modal-wrapper {
  min-height: 300px;
}

ion-checkbox {
  --checkmark-color: #000;
  --background-checked: transparent;
  --border-color: #000;
  --border-color-checked: #000;
  --checkbox-background-checked: unset !important /* fixed pink background in checkbox */;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.mobile-filter__container ion-radio {
  --color-checked: var(--ion-color-tertiary);
}

.selectdate {
  border: 2px solid var(--input--border--color);
  border-radius: 6px;
  height: 42px;
  width: 100%;

  background-image: url('./assets/icons/calendar.svg');
  background-repeat: no-repeat;
  background-size: auto 50%;
  background-position: right 13px center;

  padding-left: 16px;
}

.selectdate:focus {
  outline: none;
}

input.selecttime,
ion-datetime {
  border: 2px solid var(--input--border--color);
  border-radius: 6px;
  height: 42px;
  width: 100%;

  background-image: url('./assets/icons/time.svg');
  background-repeat: no-repeat;
  background-size: auto 60%;
  background-position: right 13px center;

  padding-left: 16px;
}

input.selecttime:focus {
  outline: none;
}

div.divider {
  width: 100%;
  height: 16px;
  border-bottom: 2px solid var(--input--border--color);
}

ion-datetime,
.centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-datetime.small-icon {
  padding-right: 24px;
  padding-left: 6px;

  background-size: auto 36%;
  background-position: right 6px center;

  font-size: 0.8rem;

  background-color: var(--ion-color-white);
}

.header-title {
  font-size: 1.4rem;
  font-weight: 500;
  @media (min-width: 992px) {
    margin-left: 10px;
  }
}

ion-item.header-dropdown-title,
div.header-dropdown-title {
  font-size: 1rem;
  font-weight: 600;
}

ion-item.header-option-title {
  font-size: 0.85rem;
  font-weight: 500;
}

ion-item.header-option-selected {
  color: var(--button-outline--color);
}

//Card styling

ion-card.color-card {
  border: 2px solid var(--button-outline--color);
  flex-grow: 10;
}

ion-card.content-card {
  border: 2px solid #979aa1;
  margin: 0;
  margin-top: 5px;
}

ion-card.content-card-noborder {
  box-shadow: none;
  margin: 12px 0px 0px 0px;
}

ion-card.content-card,
ion-card.content-card-noborder {
  ion-card-content.vertical-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

ion-select.page-select {
  min-width: 80px;
  max-width: 80px;
  height: 38px;

  position: relative;
  top: -2px;

  background-color: var(--ion-color-white);
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

ion-grid.no-padding-grid,
ion-grid.no-padding-grid-box {
  padding: 0px;

  ion-row {
    ion-col {
      padding: 0px;
    }

    border: none;
  }
}

ion-grid.no-padding-row-grid {
  padding: 0px !important;

  ion-row {
    padding: 0px !important;

    border: none;
  }
}

.no-padding-grid ion-row {
  align-items: center;
}

.line-divider {
  height: 2px;
  background-color: var(--input--border--color);
}

.password-eye-off,
.password-eye-on {
  width: 22px;
  height: 22px;

  position: absolute;
  right: 12px;
  top: calc(50% - (22px / 2));

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  cursor: pointer;

  z-index: 10000;
}

.password-eye-off {
  background-image: url('./assets/icons/pwd-eye.svg');
}

.password-eye-on {
  background-image: url('./assets/icons/pvd-eye-inactive.svg');
}

div.col-container {
  background: var(--ion-color-white);
  padding: 10px 20px;
  margin: 5px;
  border-radius: 6px;
}

ion-icon[mobile] {
  height: 20px;
  width: 20px;
  margin-right: 3px;
  fill: var(--ion-color-secondary);
  stroke: var(--ion-color-secondary);
  opacity: 0.4;
}

.icon-label-text {
  font-weight: 400;
  color: var(--ion-color-white-contrast);
}

@media print {
  ion-menu {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  ion-input.search {
    max-width: 100%;
  }
}

@media (max-width: 760px) {
  ion-router-outlet {
    ion-content {
      --padding-start: 3px;
      --padding-end: 3px;
      --backbackground: (--ion-color-white);
    }
  }
}

.custom-loading-screen {
  --backdrop-opacity: 0.75;
}

.network-alert {
  .alert-wrapper {
    --max-width: 360px;
    background-image: url('./assets/icons/no_net.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 50px;

    .alert-head {
      padding-left: 70px;
    }
  }
}

.loading-modal {
  .modal-wrapper {
    width: 100%;
    height: calc(100% + 80px);
    background: var(--ion-color-secondary);
    opacity: 1;
  }
}

ion-item.selectable-item {
  --background: transparent;
  width: 100%;
  height: 24px;
}

/*
* CUSTOM MAT-CSS-THEME
*/
@include mat.core();
$primary: (
  Main: #f500a4,
  Light: #e040fb,
  Dark: #aa00ff,
  contrast: (
    Main: white,
    Light: #000,
    Dark: white
  )
);

$candy-app-primary: mat.define-palette($primary, Main, Light, Dark);
$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$candy-app-warn: mat.define-palette(mat.$red-palette);

$candy-app-theme: mat.define-light-theme((
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
    warning: $candy-app-warn,
  ),
));

@include mat.all-component-themes($candy-app-theme);


.mat-step-icon-selected {
  background-color: var(--ion-color-secondary) !important;
}

.mat-step-icon-state-edit {
  background-color: var(--ion-color-success) !important;
}

.mat-horizontal-stepper-header-container {
  @media (max-width: 992px) {
    display: none !important;
  }
}

.mat-horizontal-content-container {
  padding: 0 !important;
  overflow: visible !important;
}

.modal-outher-container {
  &::part(scroll) {
    background-color: white;
    overflow: visible !important;
    border-radius: 6px;
  }
}

.modal-inner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  transform: translateY(-50%);
  border-radius: 6px;
  @media (max-width: 576px) {
    transform: translateY(0);
  }
  &--small {
    @media (max-width: 576px) {
      transform: translateY(-55%);
    }
  }
  &--scroll {
    max-height: 100vh;
    overflow: auto;
    height: auto;

    @media (max-width: 991px) {
      overflow: visible;
    }
  }
  &--no-transform {
    transform: translateY(0);
  }
}

.add-edit-modal {
  .scroll-y {
    touch-action: pan-y;
    overflow-y: var(--overflow);
    overscroll-behavior-y: contain;
    background-color: white;
    height: 0;
  }

  &::part(content) {
    height: 0;
    max-width: 475px;
    margin-right: 0;
    margin-left: 0;
    border-radius: 6px;
    overflow: visible !important;

    @media (max-width: 760px) {
      border: 0;
    }

    @media (max-width: 576px) {
      height: 100%;
      width: 100%;
      max-width: 100%;
      overflow: auto;
    }

    .ion-page {
      overflow: visible;
      @media (max-width: 576px) {
        overflow: auto !important;
      }
    }
  }

  // Modal na mobilce który nie jest na 100% szerokości  wysokości
  &--small {
    &::part(content) {
      @media (max-width: 760px) {
        border: 0;
      }

      @media (max-width: 576px) {
        height: 0;
        width: calc(100% - 10px);
        max-width: calc(100% - 10px);
        overflow: visible !important;
      }
      .ion-page {
        @media (max-width: 576px) {
          overflow: visible !important;
        }
      }
    }
  }
  //Modal, z większą maksymalną szerokością na desktopie
  &--large {
    &::part(content) {
      max-width: 600px;
    }
  }
  //Modal, z większą maksymalną szerokością na desktopie
  &--x-large {
    .modal-wrapper {
      max-width: 850px !important;
      --width: 850px !important;
    }
  }
}

.trans-modal .modal-wrapper {
  max-width: 550px;

  ion-content::part(scroll) {
    background-color: white;
  }

  @media (max-width: 760px) {
    border: 0;
  }
  border-radius: 6px;
}

.popover {
  &__legend-color {
    width: 24px;
    height: 24px;
  }
}

.trans-modal .modal-wrapper {
  max-height: 360px;
  max-width: 380px;
  margin-right: 0;
  margin-left: 0;
}

.trans-modal {
  .scroll-y {
    touch-action: pan-y;
    overflow-y: var(--overflow);
    overscroll-behavior-y: contain;
    background-color: white;
  }
  .modal-wrapper {
    overflow: visible;
  }
}

#deleteButton {
  margin-left: 20px;
}

#saveButton {
  margin-right: 20px;
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
}

.swiper-slide {
  height: auto !important;
  padding-bottom: 15px;
}

.swiper-pagination-bullet-active {
  background: var(--ion-color-primary) !important;
}

.swiper-pagination-horizontal {
  bottom: 0px !important;
}

.first-slide {
  .swiper-pagination-bullets {
    display: none;
  }
}

@media (min-width: 992px) {
  .swiper-pagination {
    display: none !important;
  }
}

.font-size-mobile {
  @media (max-width: 800px) {
    @media (max-width: 321px) {
      font-size: 12px;
    }
    @media (min-width: 321px) {
      font-size: 12px;
    }
  }
}

.font-size-mobile-title {
  @media (max-width: 800px) {
    font-size: 25px;
  }
}

.guest-view-card {
  $card-ios-header-padding-top: 0px !important !important !important;
}

.ion-color-fab {
  --ion-color-base: var(--ion-color-fab);
}

.ngx-dropdown-container {
  outline: none;
  hr {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .selected-items {
    margin-bottom: 0 !important;
    .selected-item {
      background-color: var(--ion-color-tertiary) !important;
    }
  }
  .available-items {
    margin-top: 0 !important;
  }
  .display-text {
    width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ngx-dropdown-button {
  height: 38px;
  border: 2px solid var(--input--border--color) !important;
  padding-right: 20px !important;

  .display-text {
    font-size: 16px;
  }

  .nsdicon-angle-down {
    right: -7px !important;
  }
}

.combobox--button {
  .ngx-dropdown-button {
    max-width: 42px;
    max-height: 42px;
  }
  .display-text {
    display: none !important;
  }
  .ngx-dropdown-list-container {
    right: 0;
  }
}

.combobox--selected-value {
  .available-items {
    .selected-item:not([tabindex]) {
      display: none !important;
    }
  }
}

.community {
  &__popover {
    .popover-content {
      max-width: 130px;
      display: flex;
      align-items: baseline;
      .popover-viewport {
        width: 100%;
      }
    }
  }
}

.user-assignment-view-share {
  &__popover {
    .popover-viewport {
      max-width: 100%;
    }
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  transform: translateY(-50%);
  border-radius: 6px;
  @media (max-width: 576px) {
    transform: translateY(0);
  }
}

.user-assignment-view-share {
  &__popover {
    .popover-content {
      width: 245px;
    }
  }
}

.card-native {
  display: block;
  height: 100%;
}

.slide-toggle-directive {
  transition: max-height 0.3s ease-in-out;
  max-height: 500px;
  &--hidden {
    overflow-y: hidden;
    max-height: 0px;
  }
  &__arrow {
    transition: transform 0.3s ease-in-out;
    &--rotate {
      transform: rotate(180deg);
    }
  }
}

.error-service {
  .alert-head {
    text-align: center;
  }
  .alert-message {
    padding-bottom: 0;
    text-align: center;
  }
}
.timepicker {
  border-radius: 6px !important;
  overflow: hidden;
}
.timepicker-custom {
  .timepicker {
    &__header {
      padding: 15px !important;
    }

    &__actions {
      padding: 15px 0 0 0 !important;
      div {
        width: 50%;
        text-align: center;
        .timepicker-button {
          border-top: 1px solid gray;
          width: 100%;
          font-weight: 400;
          span {
            font-weight: 400;
          }
        }
      }
      div:last-of-type {
        button {
          background-color: var(--ion-color-tertiary);
          color: white;
          border-radius: 0 0 6px 0;
        }
      }
    }

    &__main-content {
      border-radius: 0 0 6px 6px;
    }
    &__dial-hint {
      text-align: center;
      color: #888587;
    }
  }

  .timepicker-dial__control {
    &:focus {
      outline-color: #ff00ad !important;
    }
  }
}

.timepicker-button {
  display: inline-block;
  height: 36px;
  min-width: 88px;
  line-height: 36px;
  border: 12px;
  border-radius: 2px;
  background-color: transparent;
  text-align: center;
  transition: 450ms cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  cursor: pointer;
  outline: 0;
  font-weight: 700;
  color: var(--button-color);
  &:hover {
    background-color: rgba(153, 153, 153, 0.2);
  }
}

.ngx-dropdown-list-container {
  max-height: 50vh;
  min-width: 250px;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--ion-color-primary);
  }
}

.logo {
  height: 36px;
  width: 200px;
  margin: 0 auto;
  object-fit: contain;
}

/*
 TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.
 */
.mat-radio-label {
  margin: 0 !important;
}

.options-button {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: auto;
  margin-right: 5px;
  border: none;
  height: 36px;
  width: 36px;
  padding: 8px;
  border-radius: 20px;
  background: var(--ion-color-light);
  cursor: pointer;

  ion-icon {
    color: var(--ion-color-secondary) !important;
    height: 100%;
    width: 100%;
  }
}

.user-assignment-view-share {
  .modal-wrapper {
    height: 0 !important;
  }
}

.mobile-filter__modal-container {
  @media (max-width: 992px) {
    &::part(content) {
      width: 80vw;
      margin-left: auto;
      border-radius: 0;
    }
  }
}

.parking-view__mobile-container {
  @media (max-width: 1449px) {
    max-width: 600px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.parking-my-reservations__filter-container {
  @media (max-width: 992px) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.parking-space-list__mobile-container {
  overflow: visible;
  box-shadow: none;
  @media (max-width: 992px) {
    margin-top: 0;
    margin-bottom: 10px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.parking-reservations__mobile-fab-button-padding {
  @media (max-width: 992px) {
    padding-bottom: 3rem;
  }
}

.ngx-dropdown-button {
  min-height: 42px !important;
}

.first-run__slider {
  .swiper-pagination-bullets {
    bottom: -15px !important;
  }
  .swiper-pagination-bullet {
    background: #4c4c4c;
  }
  .swiper-pagination-bullet-active {
    background: var(--ion-color-primary) !important;
  }
  &--disable-bullets {
    .swiper-pagination-bullets {
      display: none;
    }
  }
}

.inheader {
  color: #4c4c4c;
}

.header-title {
  overflow: visible;
  ion-text {
    text-align: center;
    @media (max-width: 992px) {
      color: #424242;
      font-size: 20px;
    }
    @media (max-width: 445px) {
      font-size: 16px;
    }
  }
}

.header-toolbar {
  contain: none;
  overflow: visible;
  .toolbar-container {
    overflow: visible;
    contain: none;
  }
}
.info__question {
  &:not(:last-child) {
    margin-bottom: 2rem;
    @media (max-width: 1400px) and (min-width: 992px) {
      margin-bottom: 0;
    }
    @media (max-width: 800px) {
      margin-bottom: 0;
    }
  }
  &:last-child {
    .info__question-content-wrapper {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

.info__question-content-wrapper {
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--ion-color-light);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 10px 0;
  }

  a {
    color: var(--ion-color-tertiary);
  }

  ion-item {
    background-color: #262e41;
    border-radius: 50%;
    min-width: 42px;
    max-width: 42px;
    height: 42px;
    padding: 10px;
    ion-icon {
      color: white;
      font-size: 22px;
    }
  }

  ion-img {
    border-radius: 6px;
    overflow: hidden;
    display: inline-block;
  }

  table {
    width: 100%;
  }

  & > table {
    tr {
      display: flex;
      align-items: flex-start;
      @media (max-width: 1400px) and (min-width: 992px) {
        flex-direction: column;
      }
      @media (max-width: 800px) {
        flex-direction: column;
      }
    }
    td {
      width: 50%;
      padding: 16px;
      @media (max-width: 1400px) and (min-width: 992px) {
        width: 100%;
      }
      @media (max-width: 800px) {
        width: 100%;
      }
      @media (max-width: 1400px) {
        width: 100%;
        text-align: justify;
        p {
          margin-bottom: 0;
        }
        h3 {
          text-align: left;
        }
      }
      // Nadpisanie zagnieżdżonych elementów tabeli, które likwiduje padding i responsywność
      td {
        margin: 0;
        padding: 0 !important;
      }
      tr {
        flex-direction: row !important;
      }
    }
  }

  .icon {
    background-color: #262e41;
    border-radius: 50%;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    ion-icon {
      color: white;
      font-size: 24px;
    }
  }

  section {
    display: flex;
    padding: 2rem;
    border-radius: 6px;
    background-color: var(--ion-color-light);
    img {
      width: 80%;
    }
  }
}
.resizable-image-container {
  ion-img {
    float: right;
    width: 20.5rem;
    padding-right: 1em;
  }
  @media only screen and (max-width: 1400px) {
    ion-img {
      padding-left: 1em;
      width: 100%;
    }
  }

  tr > td:first-of-type {
    ion-img {
      padding-right: 0;
      padding-left: 0;
      float: left;
    }
    @media only screen and (max-width: 1650px) {
      ion-img {
        width: 100%;
      }
    }
  }
}
.gallery-table {
  text-align: center;
  ion-img {
    margin: 0 0.5rem 0 0.5rem;
  }
  tr > td:first-of-type {
    text-align: left;
    ion-img {
      margin: 0 1rem 0 0;
    }
  }
  tr > td:last-of-type {
    text-align: right;
    ion-img {
      margin: 0 0 0 1rem;
    }
  }
  @media (max-width: 1400px) and (min-width: 992px) {
    ion-img {
      margin: 0;
    }
    tr > td {
      padding: 8px;
      text-align: center;
    }
    tr > td:first-of-type {
      text-align: center;
      ion-img {
        margin: 0;
      }
    }
    tr > td:last-of-type {
      text-align: center;
      ion-img {
        margin: 0;
      }
    }
  }
  @media (max-width: 800px) {
    ion-img {
      margin: 0;
    }
    tr > td {
      padding: 8px;
      text-align: center;
    }
    tr > td:first-of-type {
      text-align: center;
      ion-img {
        margin: 0;
      }
    }
    tr > td:last-of-type {
      text-align: center;
      ion-img {
        margin: 0;
      }
    }
  }
}
.contact-container {
  @media (max-width: 1400px) and (min-width: 992px) {
    font-size: 11px;
    h3 {
      font-size: 16px;
    }
    img {
      height: 1em;
    }
    ion-img {
      width: 100%;
    }
  }
  @media (max-width: 800px) {
    font-size: 11px;
    h3 {
      font-size: 16px;
    }
    img {
      height: 1em;
    }
    ion-img {
      width: 100%;
    }
  }
}
.icons-padding {
  padding: 16px;
  @media (max-width: 1400px) and (min-width: 992px) {
    padding: 8px !important;
  }
  @media (max-width: 800px) {
    padding: 8px !important;
  }
}

.datetime-picker {
  &__hide-time {
    height: 1px;
    padding: 0;
  }

  &__mobile-calendar {
    border-radius: 6px;
    width: 18.5em !important;
    height: 20em !important;
  }
  &__timer-container {
    top: 0;
    right: 0;
    clip: auto;
    overflow: visible;
  }
  &__timer {
    width: 160px;
    border-radius: 6px;
    overflow: hidden;
    .owl-dt-timer {
      height: auto;
    }
  }
  &__date-timer-mobile-container {
    width: 18.5em !important;
  }
  &__rounded-corners {
    border-radius: 6px;
    overflow: hidden;
  }
}
.show-timer {
  .owl-dt-popup-container {
    width: calc(18.5em + 120px) !important;
    padding-right: 120px;
    border-radius: 6px;
    overflow: hidden;
    @media (max-width: 992px) {
      width: calc(18.5em) !important;
      padding-right: 0;
    }
  }
}

.owl-dt-calendar-cell-selected {
  background-color: var(--ion-color-tertiary) !important;
}

.owl-dt-cancel-button {
  color: black;
}
.owl-dt-confirm-button {
  background-color: var(--ion-color-tertiary) !important;
  color: white;
}

.tox-tinymce {
  border: 2px solid var(--input--border--color);
  border-radius: 6px !important;
}

.tox-dialog__footer-end {
  .tox-button:last-of-type {
    background-color: var(--ion-color-tertiary) !important;
    border: none;
  }
}

.apexcharts-tooltip {
  z-index: 10000 !important;
}

.required-information {
  margin-left: 5px !important;
  font-size: 13px !important;
  color: var(--ion-color-dark-tint) !important;
}

.form-title {
  font-size: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    font-size: 21px;
  }
}

.change-password-modal {
  .modal-wrapper {
    @media (min-width: 768px) {
      margin-top: auto;
      margin-bottom: auto;
      height: 0;
      max-width: 475px;
      margin-right: 0;
      margin-left: 0;
      overflow: visible !important;
      ion-content::part(scroll) {
        background-color: white;
        overflow: visible !important;
      }
    }
    .modal-inner-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: white;
      transform: translateY(-50%);
      border-radius: 6px;
      @media (max-width: 768px) {
        transform: translateY(0);
      }
    }
  }
}

.active-chip {
  border-color: var(--ion-color-tertiary);
  background: #ffe9f7;
  ion-icon {
    color: var(--ion-color-tertiary);
  }
  ion-label {
    color: var(--ion-color-tertiary);
  }
}

.undo-delete-comment-toast {
  --color: black;
  --background: var(--ion-color-white);
  --border-radius: 6px;
  --button-color: black !important;
}

// breadcrumbs

.xng-breadcrumb-root {
  margin: 15px 0 0px 5px !important;
}
.xng-breadcrumb-item {
  font-size: 14px;
  font-weight: 500;
}

.xng-breadcrumb-trail {
  color: var(--ion-dark-grey) !important;
}

.xng-breadcrumb-link {
  color: #000 !important;
}

.breadcrumb-margin {
  margin-left: 15px;
}

.tox-toolbar--scrolling {
  flex-wrap: wrap !important;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--ion-color-primary);
  }
}

.tox-notification--warning {
  display: none !important;
}

// Skasowanie domyślnych strzałek na inpucie type number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'],
textarea {
  -moz-appearance: textfield;
  overflow: hidden;
}
// select
.ngx-select {
  &__toggle {
    border-radius: 4px !important;
    border-color: var(--input--border--color) !important;
    border-width: 2px !important;
    min-height: 42px !important;
  }
  &__toggle-buttons {
    color: #000 !important;
  }
  &__clear-icon {
    color: #000 !important;
  }
  &__item_active {
    background-color: var(--ion-color-tertiary) !important;
  }
  &__item {
    padding: 5px !important;
    font-size: 14px !important;
  }
  &__disabled {
    cursor: default !important;
    background: transparent !important;
  }
}

.dropdown-toggle {
  height: 20px;
  content: url(./assets/icons/chevron-down-outline.svg) !important;
}
// ikona w app-select-dropdown
.nsdicon-angle-down {
  height: 20px;
  content: url(./assets/icons/chevron-down-outline.svg) !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: var(--ion-color-tertiary) !important;
}

.dropdown-menu {
  padding: 0;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--ion-color-primary);
  }
}

.ngx-select__item {
  border-bottom: 1px solid var(--input--border--color);
  hr {
    display: none;
  }
}

.priority-flag {
  color: var(--flag-color);
}

.isDisabled {
  --background: #e9ecef;
}

.margin-carPlate {
  margin-top: 10px;
}

.padding {
  &__input {
    padding: 0 16px;
  }
  &--start {
    --padding-start: 8px !important;
  }
}
// zaznaczenie w kalendarzu
.fc-timegrid-slot {
  user-select: none;
}
.ngx-select__selected-single,
.ngx-select__item {
  white-space: pre-line !important;
}