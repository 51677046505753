.fc-event-main-frame {
  display: flex;
  height: 100%;
  justify-content: left !important;
  align-items: flex-start;
}

.fc-event-title-container {
  flex-grow: 0 !important;
}

.fc-event-title,
.fc-event-time {
  text-align: center;
  white-space: normal !important;
}

.fc-event-time {
  margin: 0 5px 0 0 !important;
  font-size: 14px !important;
}

.fc-event-title {
  font-size: 15px !important;
}

@media (max-width: 576px) {
  .fc-event-title,
  .fc-event-time {
    font-size: 10px !important;
    word-break: break-all;
  }

  .fc-daygrid-event {
    font-size: 8px !important;
    .fc-event-title {
      display: none;
    }
    .fc-event-time {
      font-weight: bold;

      font-size: 12px;
    }
  }

  .fc-timeGridWeek-view {
    .fc-event-time {
      display: none;
    }
  }
}

@media (max-width: 700px) {
  full-calendar {
    margin-top: 0 !important;
    width: 100%;
    font-size: 16px;
    height: 100%;
  }

  .fc-header-toolbar {
    margin-bottom: 0 !important;
    justify-content: flex-start !important;
  }

  .fc-toolbar-title {
    font-size: 20px !important;
  }

  .fc-toolbar {
    display: flex;
    flex-direction: column;
  }

  .fc-toolbar-chunk {
    margin-bottom: 8px;
  }

  .fc-title {
    font-size: 10px;
  }
}

@media (max-width: 1200px) {
  .fc-toolbar-title {
    font-size: 20px !important;
  }
}

.fc-event-main {
  padding: 0 !important;
}

.fc-timegrid-col-frame {
  background-color: var(--ion-color-light);
}

.fc-daygrid-event {
  .fc-event-title,
  .fc-event-time {
    color: black;
  }
}

.fc-daygrid-day {
  height: 180px;
  @media (max-width: 576px) {
    height: 100px;
  }
}

.fc-timegrid-slot {
  height: 21px !important;
  @media (max-width: 576px) {
    height: 15px !important;
  }
}

.fc-daygrid-day-number {
  align-items: center;
  display: flex;
  justify-content: center;
}

.fc-daygrid-day.fc-day-today {
  background-color: #e0e0e0 !important;

  .fc-daygrid-day-number {
    width: 35px;
    height: 35px;
    color: white;
    border-radius: 50%;
    background-color: black;
    margin: 5px;
  }
}
.fc-scrollgrid-section-sticky > * {
  top: -10px !important;
}

.fc-daygrid-day-top {
  justify-content: center;
  min-height: 45px;
}

.fc-daygrid-event,
.fc-timegrid-event {
  border: 0 !important;
  cursor: pointer;
  flex-direction: column;

  align-items: baseline !important;
  padding: 6px !important;
}

.fc-daygrid-event-dot {
  display: none !important;
}

.fc-scroller {
  overflow-y: auto !important;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--ion-color-primary);
  }
}

.fc-button {
  border: 2px solid #e9e9e9 !important;
  border-radius: 6px !important;
  background-color: white !important;
  color: black !important;
  margin: 0 4px !important;
  padding: 13px 18px !important;
  &:focus {
    box-shadow: none !important;
    border: 2px solid #000000 !important;
  }
  @media (max-width: 700px) {
    padding: 4px 9px !important;
  }
}

.fc-button-active {
  border: 2px solid #000000 !important;
}

.fc-header-toolbar {
  justify-content: flex-start !important;
}

.fc-toolbar-chunk {
  &:nth-child(1) {
    // etykieta miesiąca i dnia
    margin-left: 8px;
  }
  &:nth-child(2) {
    // strzałki i przycisk "dzień"
    order: -1;
    .fc-today-button {
      order: 1;
    }
  }
  &:nth-child(3) {
    // przyciski zmiany widoków dzień - tydzień - miesiąc
    @media (min-width: 700px) {
      margin-left: auto;
    }
  }
}

.fc-curent-user {
  background-color: var(--ion-color-tertiary) !important;
  .fc-event-time {
    color: white;
  }
  .fc-event-title {
    color: white;
    opacity: 0.7;
    font-weight: 400 !important;
  }
}

.fc-other-user {
  background-color: #f9dcee !important;
  .fc-event-time {
    color: black;
  }
  .fc-event-title {
    color: var(--ion-color-tertiary);
  }
}

.fc-short-event {
  padding: 0 0 0 4px !important;
  .fc-event-main-frame {
    align-items: baseline !important;
    justify-content: flex-start !important;
    flex-direction: initial !important;
  }
  .fc-event-title {
    text-align: left;
  }
}

.fc-timegrid-now-indicator-line {
  border-top: 3px solid var(--fc-now-indicator-color, red);
}
