.pos-relative {
  position: relative;
}

.not-allowed {
  cursor: not-allowed !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.ion-content-padding-top-0 {
  --padding-top: 0;
}

.color-pink {
  color: var(--ion-color-tertiary) !important;
}

.color-light {
  color: #d3d3d4 !important;
}

.color-black {
  color: black !important;
}

.color-dark-gray {
  color: var(--ion-dark-gray);
}

.display-flex-important {
  display: flex !important;
}

.display-flex {
  display: flex;
}

.display-inline-block {
  display: inline-block;
}

.icon-and-text-in-same-line {
  display: inline-block;
  vertical-align: middle;
}

.flex-direction-column {
  flex-direction: column;
}
.flex-justify-content-flex-end {
  display: flex;
  justify-content: flex-end !important;
}

.flex-nowrap {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.min-height-auto {
  min-height: auto !important;
  --min-height: auto !important;
}
.w-100 {
  width: 100% !important;
}
.w-max-100 {
  max-width: 100% !important;
}

.w-0 {
  width: 0 !important;
}
.w-50 {
  width: 50% !important;
}
.height-0 {
  max-height: 0 !important;
  overflow: hidden;
  ion-grid {
    max-height: 0 !important;
    overflow: hidden;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-auto {
  overflow: auto;
}

ion-label {
  white-space: unset !important;
}

.space-between {
  justify-content: space-between;
}

.bold {
  font-weight: 500;
}

.more-bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.no-padding-vertical {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.no-padding-horizontal {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-padding-ion-item {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --inner-padding-start: 0px;
}

.margin-left-auto {
  margin-left: auto !important;
}

.horizontal-margin-minus-5 {
  margin: 0 -5px;
}

.horizontal-margin-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-right-minus-16 {
  margin-right: -16px;
}

.margin-top-minus-8 {
  margin-top: -8px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.horizontal-margin-minus-10 {
  margin: 0 -10px;
}

.horizontal-margin-10 {
  margin: 0 10px;
}

.margin-minus-10 {
  margin: -10px;
}

.margin-minus-20-mobile {
  @media (max-width: 992px) {
    margin: -20px;
  }
}

.margin-right-4 {
  margin-right: 4px;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-right-16 {
  margin-right: 16px !important;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-0 {
  margin-left: 0px;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-horizontal-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.margin-top-auto {
  margin-top: auto !important;
}

.margin-bottom-4 {
  margin-bottom: 4px !important;
}

.margin-bottom-3 {
  margin-bottom: 3px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}
.vertical-margin-auto {
  margin-bottom: auto;
  margin-top: auto;
}

.margin-auto {
  margin: auto;
}

.position-relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.ion-no-padding {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
}

.spin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &--full {
    background-color: var(--ion-color-light);
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: calc(100% - 56px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    @media (max-width: 996px) {
      height: calc(100% - 104px);
    }
  }
}

.no-results-small-margin {
  div {
    margin-top: 20px !important;
  }
}
.border-top {
  border-top: 2px solid var(--ion-color-light);
}
.word-break-always {
  word-break: break-all;
}

.word-break {
  word-break: break-word;
}

.disabled {
  opacity: 0.3;
}

.light {
  opacity: 0.25;
}
.blue-color {
  color: blue;
}

.rotate-90-deg {
  transform: rotate(90deg);
}

.black-color {
  color: black;
}
.move-on-hover {
  @media (min-width: 992px) {
    transition-duration: 0.5s;
    &:hover {
      transform: translate(0, -10px);
    }
  }
}
.no-padding-top {
  padding-top: 0 !important;
}
.border-bottom {
  border-bottom: 2px solid var(--ion-color-light);
}
.weight-bold {
  font-weight: bold;
}
.display-none {
  display: none !important;
}
.flex-beetwen {
  display: flex;
  justify-content: space-between;
}
.ion-img-width-unset {
  ion-img {
    width: unset !important;
  }
}
.td-text-align-left {
  td {
    text-align: left !important;
  }
}
.float-right {
  float: right;
}
.high-z-index {
  z-index: 999999;
}

// .keyboard-space {
//   padding-bottom: 250px;
// }

.word-break-always {
  word-break: break-all;
}

.font-size {
  &__16 {
    font-size: 16px;
  }
}

.card-header-layout {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
