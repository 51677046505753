// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: linear-gradient(270deg, #ff00ad, #c5017c);
  --ion-color-primary-rgb: 38, 46, 66;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #21283a;
  --ion-color-primary-tint: #3c4355;
  --ion-color-primary-background-icon: rgba(0, 0, 0, 0.1);

  --ion-color-secondary: #262e42;
  --ion-color-secondary-rgb: 38, 46, 66;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #21bcca;
  --ion-color-secondary-tint: #3cdae9;
  --ion-color-secondary-background-icon: rgba(255, 255, 255, 0.1);

  --ion-color-tertiary: #d60d8c;
  --ion-color-tertiary-rgb: 190, 70, 142;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #d60d8c;
  --ion-color-tertiary-tint: #c4a0b5;

  --ion-color-success: #6bc54b;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #f24316;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f2f3f5;
  --ion-color-light-rgb: 242, 243, 245;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #07080a;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #c7c7c7;
  --ion-color-white-tint: #eeeeee;

  --input--border--color: #e9e9e9;
  --button-outline--color: #d60d8c;
  --button-pink--color: #fbe9f5;

  --form-container-width-default: 600px;
  --form-container-width-md: 360px;
  --form-container-width-sm: 100%;

  --box-padding-vertical-desktop: 12px 0px;
  --box-padding-desktop: 12px 20px;
  --box-padding-mobile: 12px;
  --box-cell-padding-desktop: 0px 20px;
  --box-cell-padding-mobile: 0px 12px;

  --content-table-margin-desktop: 18px;
  --content-table-margin-mobile: 14px;

  --ion-color-fab: #f2f3f5;
  --ion-color-fab-rgb: 242, 243, 245;
  --ion-color-fab-contrast: #000000;
  --ion-color-fab-contrast-rgb: 0, 0, 0;
  --ion-color-fab-shade: #d7d8da;
  --ion-color-fab-tint: #f5f6f9;

  // custom style
  --ion-grid-width-xl: 1600px;
  --ion-dark-gray: #b7bac1;
  --flag-color: lightgrey;
  --ion-border-grey: #dfdfdf;
}

.guest-view-card {
  :root {
    --ion-color-primary: linear-gradient(270deg, #ff00ad, #c5017c);
    --ion-color-primary-rgb: 38, 46, 66;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #21283a;
    --ion-color-primary-tint: #3c4355;
    --ion-color-primary-background-icon: rgba(0, 0, 0, 0.1);

    --ion-color-secondary: #262e42;
    --ion-color-secondary-rgb: 38, 46, 66;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #21bcca;
    --ion-color-secondary-tint: #3cdae9;
    --ion-color-secondary-background-icon: rgba(255, 255, 255, 0.1);

    --ion-color-tertiary: #d60d8c;
    --ion-color-tertiary-rgb: 190, 70, 142;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #a73e7d;
    --ion-color-tertiary-tint: #c55999;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f2f3f5;
    --ion-color-light-rgb: 242, 243, 245;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0, 0, 0;
    --ion-color-white-shade: #c7c7c7;
    --ion-color-white-tint: #eeeeee;

    --input--border--color: #e9e9e9;
    --button-outline--color: #d60d8c;
    --button-pink--color: #fbe9f5;

    --form-container-width-default: 420px;
    --form-container-width-md: 360px;
    --form-container-width-sm: 100%;

    --box-padding-vertical-desktop: 12px 0px;
    --box-padding-desktop: 12px 20px;
    --box-padding-mobile: 12px;
    --box-cell-padding-desktop: 0px 20px;
    --box-cell-padding-mobile: 0px 12px;

    --content-table-margin-desktop: 18px;
    --content-table-margin-mobile: 14px;

    --ion-padding: 0;
  }
}

.fab-button {
  :root {
    --ion-color-primary: linear-gradient(270deg, #ff00ad, #c5017c);
    --ion-color-primary-rgb: 38, 46, 66;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #21283a;
    --ion-color-primary-tint: #3c4355;
    --ion-color-primary-background-icon: rgba(0, 0, 0, 0.1);

    --ion-color-secondary: #262e42;
    --ion-color-secondary-rgb: 38, 46, 66;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #21bcca;
    --ion-color-secondary-tint: #3cdae9;
    --ion-color-secondary-background-icon: rgba(255, 255, 255, 0.1);

    --ion-color-tertiary: #d60d8c;
    --ion-color-tertiary-rgb: 190, 70, 142;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #a73e7d;
    --ion-color-tertiary-tint: #c55999;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: red !important;
    --ion-color-light-rgb: 242, 243, 245;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: red;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0, 0, 0;
    --ion-color-white-shade: #c7c7c7;
    --ion-color-white-tint: #eeeeee;

    --input--border--color: #e9e9e9;
    --button-outline--color: #d60d8c;
    --button-pink--color: #fbe9f5;

    --form-container-width-default: 420px;
    --form-container-width-md: 360px;
    --form-container-width-sm: 100%;

    --box-padding-vertical-desktop: 12px 0px;
    --box-padding-desktop: 12px 20px;
    --box-padding-mobile: 12px;
    --box-cell-padding-desktop: 0px 20px;
    --box-cell-padding-mobile: 0px 12px;

    --content-table-margin-desktop: 18px;
    --content-table-margin-mobile: 14px;

    --ion-padding: 0;
  }
}
